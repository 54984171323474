<template>
    <CompTable
        :title="$core.getUrlParam('type') && $core.getUrlParam('type') == '1' ? '指定人群应读已读记录' : '畅联组应读已读记录'"
        ref="comTable"
        :columns="columns"
        :table-api="apiUrl"
        :dataBefore="onDataBefore"
        @on-reset="onReset"
        :dataList="dataList"
        @search="search"
    >
        <template v-slot:search="evt" v-if="$core.getUrlParam('type') && $core.getUrlParam('type') == '1'">
            <CheckboxGroup v-model="evt.search.appId">
                <Checkbox v-for="item in appId" :key="item.appId" :label="item.appId">{{ item.groupName }}</Checkbox>
            </CheckboxGroup>
        </template>
        <template v-slot:search="evt" v-else>
            <CheckboxGroup v-model="evt.search.groupId">
                <Checkbox v-for="item in groupId" :key="item.groupId" :label="item.groupId">{{ item.groupName }}</Checkbox>
            </CheckboxGroup>
        </template>
    </CompTable>
</template>
 
<script>
import Route from "../../communitymobilization/entity/Route"
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"
import proxy from "@/api/proxy"

export default {
    components: { CompTable },

    data() {
        const informationId = this.$core.getUrlParam("i") || ""

        return {
            informationId: informationId,
            groupId: null,
            columns: [],
            apiUrl: "",
            appId: null,
            dataList: [],
            isTime:false
        }
    },

    async created() {
        await this.getMethods()
    },

    methods: {
        async search(val){
            console.log('val',val);
            if(this.$core.getUrlParam('type') && this.$core.getUrlParam('type') == '1'){
                // if(!this.isTime) return
                await this.getTypeList(val.appId)
                // if(val && val.length){
                //     console.log('如果不为空直接查询',val);
                //     this.getTypeList(val)
                // }else{
                //     console.log('如果空直接查询');
                // }
            }
        },
        async getMethods() {
            var ids = []
            var list = []
            list = await Request.get("/info/api/pc/information/group/selectHasGroup", {
                informationId: this.informationId,
            })
            this.groupId = list.filter(item => item.groupType && item.groupType == "3")
            this.appId = list
                .filter(item => item.groupType && item.groupType == "7")
                .map(item => {
                    return {
                        ...item,
                        appId: item.groupId,
                    }
                })
            if (this.$core.getUrlParam("type") && this.$core.getUrlParam("type") == "1") {
                if (!this.appId || !this.appId.length) {
                    this.closeModal()
                    return
                } else {
                    this.appId.map(item => {
                        ids.push(item.appId)
                    })
                }
            } else {
                if (!this.groupId || !this.groupId.length) {
                    this.closeModal()
                    return
                } else {
                    this.groupId.map(item => {
                        ids.push(item.groupId)
                    })
                }
            }

            if (this.$core.getUrlParam("type") && this.$core.getUrlParam("type") == "1") {
                this.columns = [
                    {
                        title: "报表名称",
                        key: "groupName",
                    },
                    {
                        title: "人员总数",
                        key: "userTotal",
                    },
                    {
                        title: "阅读次数",
                        key: "pv",
                    },
                    {
                        title: "阅读人数",
                        key: "uv",
                        style: {
                            color: "#2faaf7",
                        },
                        click: evt => {
                            Route.jump("/statisticsreaddetail", {
                                i: this.informationId,
                                gi: evt.row.appId,
                                type:'1',
                                gn:evt.row.groupName
                            })
                        },
                    },
                    {
                        title: "阅读率（阅读人数/群成员数）",
                        key: "uvPercent",
                        width: 250,
                    },
                ]
                // this.apiUrl = `/syaa/api/syuser/pc/userBaseStat/countReportUv?infoId=${this.informationId}`
                
                await this.getTypeList(ids)
                this.$refs.comTable.setSearchData("appId", ids)
            } else {
                this.columns = [
                    {
                        title: "沟通组名称",
                        key: "groupName",
                    },
                    {
                        title: "群成员数",
                        key: "userTotal",
                    },
                    // {
                    //     title: "阅读次数",
                    //     key: "readTotal",
                    // },
                    {
                        title: "阅读人数",
                        key: "readUserTotal",
                        style: {
                            color: "#2faaf7",
                        },
                        click: evt => {
                            Route.jump("/statisticsreaddetail", {
                                i: this.informationId,
                                gi: evt.row.groupId,
                                gn: evt.row.groupName,
                            })
                        },
                    },
                    {
                        title: "阅读率（阅读人数/群成员数）",
                        key: "readRate",
                        width: 250,
                    },
                    {
                        title: "占比（本群阅读数/全部群阅读数）",
                        key: "readGroupRate",
                        width: 260,
                    },
                ]
                this.apiUrl = `/info/api/pc/information/group/selectGroupView?informationId=${this.informationId}`
                this.$refs.comTable.setSearchData("groupId", ids)
            }

            setTimeout(() => {
                if(!this.$core.getUrlParam('type') || this.$core.getUrlParam('type') == ''){
                    this.$refs.comTable.onSearch()
                }
            }, 1000)
        },
        async getTypeList(ids){
            this.isTime = true
            await this.$.ajax({
                    url: proxy["/syaa"].target + "/api/syuser/pc/userBaseStat/countReportUv",
                    type: "GET",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: window.sessionStorage.getItem("accessToken"),
                    },
                    data: {
                        infoId: this.informationId,
                        appId: ids && ids.length && ids instanceof Array ? ids.join(",") : ids,
                    },
                    success: res => {
                        this.dataList = res.dataList.map(item => {
                            let groupName = ""
                            this.appId.map(items => {
                                if (item.appId == items.appId) {
                                    groupName = items.groupName
                                }
                            })
                            return {
                                ...item,
                                groupName,
                            }
                        })
                    },
                })
        },
        closeModal() {
            this.$Modal.confirm({
                title: "提示",
                content: "暂无应读范围，通过群推送的用户会统计应读已读结果",
                okText: "前去推送到群",
                cancelText: "取消",
                onOk: () => {
                    var index = parent.layer.getFrameIndex(window.name)
                    if (index) {
                        //先得到当前iframe层的索引
                        parent.layer.close(index) //再执行关闭
                    }
                },
            })
        },
        onDataBefore(data) {
            if (this.$core.getUrlParam("type") && this.$core.getUrlParam("type") == "1") {
                return {
                    appId: data.appId && data.appId.length ? data.appId.join(",") : "",
                }
            } else {
                return {
                    groupId: data.groupId && data.groupId.length ? data.groupId.join(",") : "",
                }
            }
        },
        onReset() {
            if (this.$core.getUrlParam("type") && this.$core.getUrlParam("type") == "1") {
                this.$refs.comTable.setSearchData("appId", [])
                this.getTypeList('')
            } else {
                this.$refs.comTable.setSearchData("groupId", [])
            }
        },
    },
}
</script>
